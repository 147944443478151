<template>
  <div>
    <Breadcrumbs title="Student Cards" />
    <div class="container-fluid">
      <div class="row">

        <div class="col-12">
          <div class="card" v-if="user">
            <div class="card-body">

              <div class="row">

                <div class="col-12 col-lg-3">
                  <b-form-group label="Institute *">
                    <b-form-select @change="instituteChanged" class="form-control border" size="lg" v-model="form.institute_id" :options="user.institutes.filter(inst => inst.name !== 'Migration')" value-field="id" text-field="name" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Institutes --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3" v-if="teachers">
                  <b-form-group label="Teacher *">
                    <b-form-select @change="teacherChanged" class="form-control border" size="lg" v-model="form.teacher_id" :options="teachers" value-field="id" text-field="text" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Teachers --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Class *">
                    <b-form-select class="form-control border" size="lg" :disabled="!class_details.length" v-model="form.class_detail_id" :options="class_details" value-field="id" text-field="text" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Classes --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="hasError('class_detail_id')">{{getError('class_detail_id')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Student NIC">
                    <b-form-input v-model="form.nic" class="btn-square border" @keydown.enter="searchStudentByNIC" required  />
                    <b-form-invalid-feedback :state="hasError('nic')">{{getError('nic')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-12 text-right">
                  <b-button variant="primary" class="mx-1" @click="searchStudent">Search</b-button>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card" v-if="totalRows">
            <div class="card-body">

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template class="text-center" #cell(active)="row">
                    {{ row.item.active ? "Yes" : "No" }}
                  </template>
                </b-table>
              </div>

              <b-row>
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import API from '@/services/api'

export default {
  name: "StudentCardList",
  data() {
    return {
      errorBag: null,
      filter: '',
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true, },
        { key: 'institute.name', label: 'Institute', sortable: true, },
        { key: 'student.nic', label: 'NIC', sortable: true, },
        { key: 'student.first_name', label: 'First Name', sortable: true, },
        { key: 'student.last_name', label: 'Last Name', sortable: true, },
        { key: 'active', label: 'Active', sortable: true, class: 'text-center' },
        // { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
      form : {
        institute_id: null,
        teacher_id: null,
        class_detail_id: null,
        nic: null
      },
      pdf_loaded: false,
      pdf_loading: false,
      base64_data: ''
    }
  },
  created() {
    this.$store.dispatch('auth/getUser')
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'teachers' })
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/authenticated',
    teachers: 'select/teachers',
    institutes: 'select/institutes',
    class_details: 'select/class_details',
  }),
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    assignNIC(value){ this.nic = value },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },

    async instituteChanged() {
      this.form.class_detail_id = null
      await this.$store.dispatch('select/fetchRecords', { id: 'teachers', params: { institute_id: this.form.institute_id } })
    },

    async teacherChanged() {
      this.form.class_detail_id = null
      await this.$store.dispatch('select/fetchRecords', { id: 'class-details', params: { institute_id: this.form.institute_id, teacher_id: this.form.teacher_id } })
    },

    searchStudentByNIC() {
      if(this.form.nic.length > 3) {
        this.searchStudent()
      }
    },
    async searchStudent() {
      console.log('form', this.form)
      try {
        this.pdf_loaded = false
        this.base64_data = ''
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
        await API.get('/api/backend/student-cards', params).then(response => {
          console.log('response', response.data.data)
          if (response.data.success) {
            this.totalRows = response.data.data.total
            this.tableRecords = response.data.data.data
          } else {
            this.errorBag = response.data
          }
        }).catch(error => {
          console.log('error', error)
        })
      } catch (error) {
        console.log('catch_error', error)
      } finally {
        this.pdf_loaded = true
      }

    },
    async generateCards() {
      console.log('form', this.form)
      this.pdf_loaded = false
      this.pdf_loading = true
      this.base64_data = ''
      try {
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
        await API.post('/api/backend/student-cards', params)
            .then(response => {
              console.log('response', response.data)
              // window.open(``, "_blank")
              this.base64_data = response.data
              this.pdf_loaded = true
              // if (response.data.success) {
              //   // this.totalRows = response.data.data.total
              //   // this.tableRecords = response.data.data.data
              //   window.open(response.data.data, "_blank");
              //
              // } else {
              //   console.log('not found')
              // }
            }).catch(error => {
              console.log('error', error)
            }).finally(() => {
              this.pdf_loading = false
            })
      } catch (error) {
        this.pdf_loading = false
        console.log('catch_error', error)
      }

    },
  }
}
</script>

<style scoped>

</style>
